import React from 'react'
import { Helmet } from 'react-helmet'
import { UIStore } from 'stores/UIStore'
import { inject, observer } from 'mobx-react'
import { HeroImage } from '../../components/HeroImage/HeroImage'
import Header from '../../components/Header/Header'
import { VehicleContainer } from '../../components/VehicleContainer/VehicleContainer'
import Vehicles from '../../config/Vehicles'
import Footer from '../../components/Footer/Footer'
import { images } from '../../images/_images'
import 'screens/Indian/IndianMotorcyclePage.scss'

@inject('uiStore')
@observer
export default class HomePage extends React.Component<{ uiStore?: UIStore }> {
    public render(): JSX.Element {
        return (
            <div className="app_container_indian">
                <Helmet>
                    <title>Indian Motorcycles</title>
                    <link id="favicon" rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon-indian.png"></link>
                    <link id="manifest" rel="manifest" href="/manifest-indian.json" />
                </Helmet>
                
                <Header 
                    linkURL='https://www.indianmotorcycle.com/en-us/'
                    className='app_header_indian'
                    ImageLeft={images.indianLogo}
                    ImageLeftAlt="Indian Motorcycles logo"
                    ImageRight={images.indianChooseWisely}
                    ImageRightAlt="Choose Wisely"
                />

                <HeroImage 
                    TitleText='Go Your Own Way'
                    SubText={`Since 1901, Indian Motorcycle<sup>®</sup> has been the choice of riders who make their own rules. Our commitment to American craftsmanship, fueled by huge investments in research and product development, ensures we’re the choice for riders who make their own rules.`}
                    CallToAction='Check out a few of our products below'
                    Image={images.indianWhoWeAre}
                    ImageMobile={images.indianWhoWeAreMobile}
                    ImageAlt="People having fun"
                />

                <VehicleContainer type={Vehicles.FTR} />
                <VehicleContainer type={Vehicles.Bobber} />
                <Footer siteName="Indian Motorcycle International, LLC" />
            </div>
        )
    }
}
