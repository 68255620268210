import React from 'react'
import { Colors } from 'config/Colors'
import { Barlow } from 'config/StyleConfig'
import 'components/NavBar/NavButton.scss'

export const NavButton = (props: { uid: string; isSelected: boolean; name: string; onSelect: () => void }) => {
    const isSelectedStyle = (isSelected: boolean) => {
        if (isSelected) {
            return '2px solid ' + Colors.navBar
        }

        return '2px solid #ebebeb'
    }

    const isSelectedText = (isSelected: boolean) => {
        if (isSelected) {
            return { color: Colors.navBar, fontFamily: Barlow.medium, fontWeight: 200 }
        }

        return {}
    }

    const onClick = () => {
        props.onSelect()

        const heroImage = document.getElementById('hero_image')!
        const headerBar = document.getElementById('header_bar')!
        const vb = document.getElementById(props.uid)!

        if (!heroImage || !headerBar || !vb) {
            return
        }

        const navLockThreshold = heroImage.getBoundingClientRect().height + headerBar.getBoundingClientRect().height

        let vehicleBox = vb.getBoundingClientRect().height

        let yPos = 0

        switch (props.uid) {
            case 'off_road_start':
                yPos = navLockThreshold
                break
            case 'on_road_start':
                yPos = navLockThreshold + 140 + vehicleBox * 4
                break
            case 'water_start':
                yPos = navLockThreshold + vehicleBox * 8
                break
        }

        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style

        if (supportsNativeSmoothScroll) {
            window.scrollTo({ left: 0, top: yPos, behavior: 'smooth' })
        } else {
            window.scrollTo(0, yPos)
        }
    }

    return (
        <div className='nav-button'
            onClick={onClick}
            style={{ borderBottom: isSelectedStyle(props.isSelected) }}
        >
            <p style={{ ...isSelectedText(props.isSelected)}}>
                {props.name}
            </p>
        </div>
    )
}