import { images } from 'images/_images'
import React from 'react'
import { UIStore } from 'stores/UIStore'
import { inject, observer } from 'mobx-react'
import 'components/HeroImage/HeroImage.scss'

interface Props {
    uiStore?: UIStore
    TitleText: string
    SubText: string
    CallToAction: string
    Image: any,
    ImageMobile: any
    ImageAlt: string
}

@inject('uiStore')
@observer
export class HeroImage extends React.Component<Props> {
    private getHeaderAndHeroHeight = (): number => {
        const heroImage = document.getElementById('hero_image')!
        const headerBar = document.getElementById('header_bar')!
        if (!heroImage || !headerBar) {
            return 0
        }

        return heroImage.getBoundingClientRect().height + headerBar.getBoundingClientRect().height
    }

    private scroll = () => {
        const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style
        if (supportsNativeSmoothScroll) {
            window.scrollTo({ left: 0, top: this.getHeaderAndHeroHeight(), behavior: 'smooth' })
        } else {
            window.scrollTo(0, this.getHeaderAndHeroHeight())
        }
    }

    public render(): JSX.Element {
        let img = this.props.uiStore!.windowWidth > 1024 ? this.props.Image : this.props.ImageMobile

        return (
            <div 
                className="hero_image_container" 
                id="hero_image"
                style={{ backgroundImage: `url('${img}')` }}
            >
                <p className="header_text">{this.props.TitleText}</p>
                <p className="sub_text" dangerouslySetInnerHTML={{__html: this.props.SubText}} />

                <div className="button_container">
                    <p className="directions">
                        {this.props.CallToAction}
                    </p>

                    <div className="scrollButton" onClick={this.scroll}>
                        <img src={images.downArrow} alt="Scroll down" />
                    </div>
                </div>
            </div>
        )
    }
}
