import React from 'react'
import { Link } from 'react-router-dom'
import Vehicle from '../../config/Vehicle'
import { images } from 'images/_images'
import 'screens/VehicleModelPage/VehicleModelPage.scss'

export default class VehicleModel extends React.Component<{ type: Vehicle }> {
    public state = {
        loading: false
    }
    public render(): JSX.Element {
        return (
            <div className="vehicle-model-page">
                <model-viewer
                    class='model-viewer'
                    shadow-intensity="0"
                    src={this.props.type.glb}
                    ios-src={this.props.type.usdz}
                    background-color="#ebebeb"
                    ar
                    camera-controls
                    camera-orbit="45deg 90deg auto"
                    quick-look-browsers="safari chrome"
                    interaction-prompt="auto"
                >
                    <button slot="ar-button" className="btn">
                        <img className="cta" alt="View In 3D" src={images.viewInAr} />
                    </button>

                    <div className="header_text_container extra">
                        <p className="car_header">{this.props.type.name}</p>
                        <p className="car_sub_text">{this.props.type.subText}</p>
                    </div>

                    <Link to="/">
                        <img 
                            className=''
                            src={images.closeIcon} 
                            alt="Close"
                            style={{ position: 'absolute', top: 28, right: 22, height: 40, width: 40, zIndex: 999 }} 
                        />
                    </Link>
                </model-viewer>
            </div>
        )
    }
}
