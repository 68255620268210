import React from 'react'
import 'components/Footer/Footer.scss'

interface Props {
    siteName?: string
}

export default class Footer extends React.Component<Props> {
    render() {
        return (
            <div className="footer">
                <p>© Copyright {this.props.siteName ? this.props.siteName : 'Polaris' }</p>
            </div>
        )
    }
}