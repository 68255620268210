import React from 'react'
import cx from 'classnames'
import { UIStore } from 'stores/UIStore'
import 'components/Header/Header.scss'

interface Props {
    uiStore?: UIStore
    className?: string
    linkURL: string
    ImageLeft: any
    ImageLeftAlt: string
    ImageRight: any
    ImageRightAlt: string
}

export default class Header extends React.Component<Props> {
    render() {
        return (
            <div className={cx("app_header", this.props.className)} id="header_bar">
                <a href={this.props.linkURL} className="logo-link">
                    <img 
                        src={this.props.ImageLeft} 
                        className="left_img" 
                        alt={this.props.ImageLeftAlt} 
                    />
                </a>

                <div className={cx("header_tagline", this.props.className)} id="header_tagline">THINK OUTSIDE</div>
            </div>
        )
    }
}
