import React from 'react'
import { Helmet } from 'react-helmet'
import { UIStore } from 'stores/UIStore'
import { inject, observer } from 'mobx-react'
import { HeroImage } from '../../components/HeroImage/HeroImage'
import Header from '../../components/Header/Header'
import { NavBar } from '../../components/NavBar/NavBar'
import { VehicleContainer } from '../../components/VehicleContainer/VehicleContainer'
import Vehicles from '../../config/Vehicles'
import Footer from '../../components/Footer/Footer'
import { images } from '../../images/_images'
import 'screens/HomePage/HomePage.scss'

@inject('uiStore')
@observer
export default class HomePage extends React.Component<{ uiStore?: UIStore }> {
    public render(): JSX.Element {
        return (
            <div className="app_container">
                <Helmet>
                    <title>Polaris</title>
                    <link id="favicon" rel="shortcut icon" href="/favicon.ico" />
                    <link id="manifest" rel="manifest" href="/manifest.json" />
                </Helmet>

                <Header
                    linkURL='/'
                    ImageLeft={images.polarisLogo}
                    ImageLeftAlt="Polaris logo"
                    ImageRight={images.thinkOutside}
                    ImageRightAlt="Think Outside"
                />

                <HeroImage
                    TitleText='CREATE MEMORIES AND FIND ADVENTURE WITH POLARIS'
                    SubText={`Over 65 years ago, our founders created an early snowmobile to better travel through northern Minnesota winters. Ever since, that same ingenuity and ability to THINK OUTSIDE has driven Polaris and our brands forward as we pioneer product breakthroughs and enriching experiences that help people work and play outside.`}
                    CallToAction='Check out just a few of our products below'
                    Image={images.heroImage}
                    ImageMobile={images.mobileHero}
                    ImageAlt="People having fun"
                />

                <NavBar />
                <VehicleContainer type={Vehicles.RZR} />
                <VehicleContainer type={Vehicles.General} />
                <VehicleContainer type={Vehicles.Ranger} />
                <VehicleContainer type={Vehicles.FourWheeler} />
                <VehicleContainer type={Vehicles.Snowmobile} />
                <VehicleContainer type={Vehicles.FTR} />
                <VehicleContainer type={Vehicles.SlingShotR} />
                <VehicleContainer type={Vehicles.Bobber} />
                <VehicleContainer type={Vehicles.Boat} />
                <VehicleContainer type={Vehicles.Godfrey} />
                <VehicleContainer type={Vehicles.Hurricane} />
                <Footer />
            </div>
        )
    }
}
