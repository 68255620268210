import { images } from '../images/_images'
import Vehicle from './Vehicle'

const Vehicles = {
    RZR: {
        route: '/ar_model/rzr',
        routeKey: `RZR`,
        name: 'RZR Pro XP Ultimate',
        subText: 'Experience the power of pro.',
        image: images.poster_RZR,
        class: 'rzr',
        link: `https://rzr.polaris.com/en-us/rzr-pro-xp/`,
        scrollID: 'off_road_start',
        usdz: require('../usdz/rxr pro xp.usdz'),
        glb: require('../glb/rxr pro xp.glb')
    } as Vehicle,
    Ranger: {
        route: '/ar_model/ranger',
        routeKey: `Ranger`,
        name: 'Ranger 1000',
        subText: 'The undisputed leader in utility side by sides.',
        image: images.poster_Ranger,
        class: 'ranger',
        link: `https://ranger.polaris.com/en-us/ranger-1000/`,
        usdz: require('../usdz/_baked_ranger-upd.usdz'),
        glb: require('../glb/_baked_ranger-upd.glb')
    } as Vehicle,
    General: {
        route: '/ar_model/general',
        routeKey: `General`,
        name: 'General XP 1000 Deluxe',
        subText: 'Elevate your adventure.',
        image: images.poster_General,
        class: 'ranger',
        link: `https://general.polaris.com/en-us/general-xp-1000-eps-deluxe/`,
        usdz: require('../usdz/gnrl xp 1000.usdz'),
        glb: require('../glb/gnrl xp 1000.glb')
    } as Vehicle,
    FourWheeler: {
        route: '/ar_model/sportsman',
        routeKey: `FourWheeler`,
        name: 'Sportsman XP 1000 S',
        subText: 'Unstoppable performance for any task or trail.',
        image: images.poster_FourWheeler,
        class: 'fourwheeler',
        link: `https://atv.polaris.com/en-us/sportsman-xp-1000-s/`,
        usdz: require('../usdz/sportsman xp 1000 s.usdz'),
        glb: require('../glb/sportsman xp 1000 s.glb')
    } as Vehicle,
    Snowmobile: {
        route: '/ar_model/indy_adventure',
        routeKey: `Snowmobile`,
        name: 'INDY Adventure 137',
        subText: 'Ultra performance and trail versatility.',
        image: images.poster_Snowmobile,
        class: 'snowmobile',
        link: `https://snowmobiles.polaris.com/en-us/indy/xc-137/`,
        usdz: require('../usdz/indy137.usdz'),
        glb: require('../glb/INDY XC 137 - RED BLACK - merged.glb')
    } as Vehicle,
    FTR: {
        route: '/ar_model/ftr',
        routeKey: `FTR`,
        name: 'Indian Motorcycle FTR',
        subText: 'Where every intersection is a starting line.',
        image: images.poster_IndianFTR,
        class: 'indian_ftr',
        link: `https://www.indianmotorcycle.com/en-us/ftr1200-s/`,
        scrollID: 'on_road_start',
        usdz: require('../usdz/FTR 1200 S.usdz'),
        glb: require('../glb/FTR 1200 S.glb')
    } as Vehicle,
    // SlingShot: {
    //     routeKey: `SlingShot`,
    //     name: 'Slingshot SLR',
    //     subText: 'The ultimate joyride.',
    //     image: images.poster_Slingshot,
    //     class: 'slingshot',
    //     link: `https://slingshot.polaris.com/en-us/slingshot-slr/`,
    //     usdz: require('../../usdz/slingshot.usdz'),
    //     glb: require('../../glb/Slingshot merged unwrapped.glb')
    // } as Vehicle,
    SlingShotR: {
        route: '/ar_model/slingshotr',
        routeKey: `SlingShotR`,
        name: '2020 Slingshot R',
        subText: 'Be the showstopper.',
        image: images.poster_2020SlingshotR,
        class: 'slingshot',
        link: `https://slingshot.polaris.com/en-us/slingshot-r/`,
        usdz: require('../usdz/slingshot 2020.usdz'),
        glb: require('../glb/slingshot 2020.glb')
    } as Vehicle,
    Bobber: {
        route: '/ar_model/bobber',
        routeKey: `Bobber`,
        name: 'Indian Motorcycle Scout Bobber',
        subText: 'An icon reborn.',
        image: images.poster_IndianBobber,
        class: 'bobber',
        link: `https://www.indianmotorcycle.com/en-us/scout-bobber/`,
        usdz: require('../usdz/bobber.usdz'),
        glb: require('../glb/bobber.glb')
    } as Vehicle,
  Boat: {
      route: '/ar_model/bennington',
      routeKey: `Boat`,
      name: 'QX SPORT LINE - 25 QX SPORT',
      subText: 'The pinnacle in boating excellence.',
      image: images.poster_Bennington,
      class: 'boat',
      link: `https://www.benningtonmarine.com/en-us/q-series/qx-sport/`,
      scrollID: 'water_start',
      usdz: require('../usdz/b_qx_sport.usdz'),
      glb: require('../glb/b_qx_sport.glb')
  } as Vehicle,
  Godfrey: {
    route: '/ar_model/godfrey',
    routeKey: `Godfrey`,
    name: 'AquaPatio 256 SBW',
    subText: '',
    image: images.poster_Godfrey,
    class: 'boat',
    link: `https://www.godfreypontoonboats.com/en-us/aquapatio/`,
    usdz: require('../usdz/godfrey.usdz'),
    glb: require('../glb/godfrey.glb')
} as Vehicle,
Hurricane: {
    route: '/ar_model/hurricane',
    routeKey: `Hurricane`,
    name: 'SUNDECK 2690 OB',
    subText: '',
    image: images.poster_Hurricane,
    class: 'boat',
    link: `https://www.hurricaneboats.com/en-us/sundeck-2690-ob/specs/`,
    usdz: require('../usdz/hurricane.usdz'),
    glb: require('../glb/hurricane.glb')
} as Vehicle
}

export default Vehicles