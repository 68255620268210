import React from 'react'
import '@google/model-viewer'
import cx from 'classnames'
import { UIStore } from 'stores/UIStore'
import { inject, observer } from 'mobx-react'
import Vehicle from '../../config/Vehicle'
import { images } from '../../images/_images'
import 'components/VehicleContainer/VehicleContainer.scss'

var mobile = require('is-mobile') // i may be an idiot, but this wont import using `import _ from _`

@inject('uiStore')
@observer
export class VehicleContainer extends React.Component<{ uiStore?: UIStore; type: Vehicle }> {
    public state = {
        shouldShowModel: false,
        loading: false,
        glbUrl: ""
    }

    componentDidMount() {
        const glbFileUrl = window.location.origin + this.props.type.glb
        this.setState({
            glbUrl: glbFileUrl
          });

        const modelViewer = document.getElementById('mobile-model-' + this.props.type.class)! as any
        if (!modelViewer) {
            return
        }

        modelViewer.exposure = 1
        modelViewer.stageLightIntensity = 0.5
        modelViewer.shadowIntensity = 0
    }

    private onClickImage = () => {
        this.setState({ shouldShowModel: true })
    }

    public render(): JSX.Element {
        var ua = window.navigator.userAgent
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
        var webkit = !!ua.match(/WebKit/i)
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i)

        const isMobile = mobile({ ua: navigator.userAgent, tablet: true })
        return (
            <div className={cx('vehicle_container', !isMobile && 'zoom')} id={this.props.type.scrollID}>
                <div className={`header_text_container text-${this.props.type.routeKey}`}>
                    <p className="car_header">{this.props.type.name}</p>
                    <p className="car_sub_text">{this.props.type.subText}</p>
                </div>

                <img
                    className={cx('poster', 'poster-' + this.props.type.class)}
                    src={this.props.type.image}
                    alt=""
                />

                {!isMobile && this.state.shouldShowModel && (
                    <model-viewer
                        id={`exposure${this.props.type.class}`}
                        class="modelView"
                        shadow-intensity="0"
                        src={this.props.type.glb}
                        ios-src={this.props.type.usdz}
                        background-color="#ebebeb"
                        ar
                        quick-look-browsers="safari chrome"
                        auto-rotate
                        camera-controls
                        camera-orbit="45deg 90deg 500%"
                        interaction-policy="allow-when-focused"
                        style={{ height: `100%`, width: `100%`, outline: 'none' }}
                        interaction-prompt="auto"
                    />
                )}
                {isMobile && (
                    <React.Fragment>
                        {!iOSSafari ? (
                            <a className="cta cta--cube" href={`intent://arvr.google.com/scene-viewer/1.0?file=${this.state.glbUrl}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`}>
                                <img alt="Cube outline" src={images.box3D} />
                                View in 3D
                            </a>
                        ) : (
                            <a className="cta cta--cube" href={this.props.type.usdz} rel="ar">
                                <img alt="Cube outline" src={images.box3D} />
                                View in 3D
                            </a>
                        )}
                    </React.Fragment>
                )}
                
                <a className="cta" target="_blank" rel="noopener noreferrer" href={this.props.type.link}>Learn More</a>

                {!isMobile && !this.state.shouldShowModel && (
                    <img className="rotate_button" alt="Rotate" src={images.rotationButton} onClick={() => this.onClickImage()} />
                )}
            </div>
        )
    }
}
