import React, { CSSProperties } from 'react'
import { UIStore } from 'stores/UIStore'
import { inject, observer } from 'mobx-react'
import { NavButton } from './NavButton'

var mobile = require('is-mobile')

@inject('uiStore')
@observer
export class NavBar extends React.Component<{ uiStore?: UIStore }> {
    public state = {
        selected: 1,
        fixedStyle: undefined,
        isScrolling: undefined
    }

    public componentDidMount() {
        // could have done this way differently, i didn't need to send this to the store
        // idk what I was thinking
        // but its done and I'm sorry. :|
        this.props.uiStore!.setScrollListener(this.onScroll)
    }

    private getHeaderAndHeroHeight = () => {
        const heroImage = document.getElementById('hero_image')!
        const headerBar = document.getElementById('header_bar')!

        if (!heroImage || !headerBar) {
            return 0
        }

        return heroImage.getBoundingClientRect().height + headerBar.getBoundingClientRect().height
    }

    private onScroll = (scrollPosition: number) => {
        const navLockThreshold = this.getHeaderAndHeroHeight()

        if (scrollPosition >= navLockThreshold) {
            //setState for fixed style but only if needed (this runs every frame of scrolling)
            if (!this.state.fixedStyle) {
                this.setState({
                    fixedStyle: styles.fixedStyle
                })
            }
        } else {
            this.setState({ fixedStyle: undefined })
        }

        if (this.state.isScrolling) {
            // if we're scrolling we dont want to update the scroll indicator
            return
        }

        this.updateNavBarSelectionBasedOnScrollPosition(scrollPosition)
    }

    private updateNavBarSelectionBasedOnScrollPosition = (scrollPosition: number) => {
        const vc = document.getElementById('on_road_start')!
        if (!vc) {
            return
        }

        const heightOfVehicleContainer = vc.getBoundingClientRect().height
        const navLockThreshold = this.getHeaderAndHeroHeight()


        const onRoadTabPosition = navLockThreshold + 140 + heightOfVehicleContainer * 4
        const waterTabPosition = navLockThreshold + heightOfVehicleContainer * 8

        if (scrollPosition >= 0 && scrollPosition < onRoadTabPosition) {
            this.setState({ selected: 1 })
        }

        if (scrollPosition >= onRoadTabPosition && scrollPosition < waterTabPosition) {
            this.setState({ selected: 2 })
        }

        if (scrollPosition >= waterTabPosition) {
            this.setState({ selected: 3 })
        }
    }

    private onSelect = (num: number) => {
        this.setState({ selected: num })
        // tricky trick where if this gets called mroe than once a true/false wont work since the earlier call will remove the timeout resulting in 'isScrolling' flipping too early.
        // So I create a uniq id so we turn it off on the last call of this function, the 1000ms is a guess :)

        const id = Math.random() + 'x'
        this.setState({ isScrolling: id })

        setTimeout(() => {
            if (this.state.isScrolling === id) {
                this.setState({ isScrolling: undefined })
            }
        }, 1000)
    }

    public render(): JSX.Element | null {
        const isMobile = mobile({ ua: navigator.userAgent, tablet: true })

        if (isMobile) {
            return null // no nav bar for the mobile peoples
        }

        const regular = styles.regularStyle
        const fixed = this.state.fixedStyle
        const containerStyle = !this.state.fixedStyle ? regular : fixed

        return (
            <>
                <div style={containerStyle as CSSProperties} id="navBar">
                    <NavButton uid="off_road_start" isSelected={this.state.selected === 1} onSelect={() => this.onSelect(1)} name="Off-Road" />
                    <NavButton uid="on_road_start" isSelected={this.state.selected === 2} onSelect={() => this.onSelect(2)} name="On-Road" />
                    <NavButton uid="water_start" isSelected={this.state.selected === 3} onSelect={() => this.onSelect(3)} name="Marine" />
                </div>
                {fixed && <div style={{ height: 80 }} />}
            </>
        )
    }
}

const styles = {
    fixedStyle: {
        height: 100,
        width: '100%',
        backgroundColor: 'white',
        flexDirection: 'row',
        display: 'flex',
        padding: 25,
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 25,
        position: 'fixed',
        left: 0,
        zIndex: 9999
    },
    regularStyle: {
        height: 100,
        width: `100%`,
        backgroundColor: 'white',
        marginTop: -40,
        flexDirection: 'row',
        display: 'flex',
        padding: 25,
        paddingLeft: 40,
        paddingRight: 40,
        marginBottom: 25
    }
}
