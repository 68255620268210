import { observable, computed } from 'mobx'

export class UIStore {
    @observable private _windowWidth: number
    @observable private _windowHeight: number
    @observable private _heroImageSize: { height: number; width: number } = { height: 0, width: 0 }
    @observable private _model: { usdz: any; glb: any } | undefined

    @computed public get model() {
        return this._model
    }

    public setModel(usdz: any, glb: any) {
        this._model = {
            usdz,
            glb
        }
    }

    @computed public get windowWidth() {
        return this._windowWidth
    }

    @computed public get windowHeight() {
        return this._windowHeight
    }

    @computed public get heroImageSize() {
        return this._heroImageSize
    }

    public setHeroImageSize(size: { height: number; width: number }) {
        this._heroImageSize = size
    }

    public setScrollListener(callback: (scrollY: number) => void) {
        this.onScrollCallback = callback
    }

    private onScrollCallback = (x: number) => {}

    private onScroll = () => {
        this.onScrollCallback(document.documentElement.scrollTop)
    }

    constructor() {
        window.addEventListener('resize', this.onResize)
        window.addEventListener('scroll', this.onScroll)
        this._windowWidth = window.innerWidth
        this._windowHeight = window.innerHeight
    }

    private onResize = () => {
        this._windowWidth = window.innerWidth
        this._windowHeight = window.innerHeight
    }
}
