import React from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'mobx-react'
import { UIStore } from 'stores/UIStore'
import { Router, Route, Switch } from 'react-router'
import { createBrowserHistory } from 'history'
import Vehicles from './config/Vehicles'
import HomePage from './screens/HomePage/HomePage'
import IndianMotorcyclePage from './screens/Indian/IndianMotorcyclePage'
import VehicleModelPage from './screens/VehicleModelPage/VehicleModelPage'
import 'App.scss'
import './config/Barlow/stylesheet.css'
import './config/styleReset.css'

const uiStore = new UIStore()
const history = createBrowserHistory()
const tagManagerArgs = {
    gtmId: 'G-JBHEEGMSQF'
}

TagManager.initialize(tagManagerArgs)

export default class App extends React.Component {
    public render(): JSX.Element {
        return (
            <React.Fragment>
                <Provider uiStore={uiStore}>
                    <Router history={history}>
                        <Switch>
                            <Route exact path='/'>
                                <HomePage />
                            </Route>

                            <Route path='/indian-motorcycle'>
                                <IndianMotorcyclePage />
                            </Route>

                            <Route path={Vehicles.RZR.route}>
                                <VehicleModelPage type={Vehicles.RZR} />
                            </Route>
                            <Route path={Vehicles.General.route}>
                                <VehicleModelPage type={Vehicles.General} />
                            </Route>
                            <Route path={Vehicles.Ranger.route}>
                                <VehicleModelPage type={Vehicles.Ranger} />
                            </Route>
                            <Route path={Vehicles.FourWheeler.route}>
                                <VehicleModelPage type={Vehicles.FourWheeler} />
                            </Route>
                            <Route path={Vehicles.Snowmobile.route}>
                                <VehicleModelPage type={Vehicles.Snowmobile} />
                            </Route>
                            <Route path={Vehicles.FTR.route}>
                                <VehicleModelPage type={Vehicles.FTR} />
                            </Route>
                            <Route path={Vehicles.SlingShotR.route}>
                                <VehicleModelPage type={Vehicles.SlingShotR} />
                            </Route>
                            <Route path={Vehicles.Bobber.route}>
                                <VehicleModelPage type={Vehicles.Bobber} />
                            </Route>
                            <Route path={Vehicles.Boat.route}>
                                <VehicleModelPage type={Vehicles.Boat} />
                            </Route>
                            <Route path={Vehicles.Godfrey.route}>
                                <VehicleModelPage type={Vehicles.Godfrey} />
                            </Route>
                            <Route path={Vehicles.Hurricane.route}>
                                <VehicleModelPage type={Vehicles.Hurricane} />
                            </Route>

                            {/* A catch-all to render the homepage. This must be LAST in the list */}
                            <Route path="*">
                                <HomePage />
                            </Route>
                        </Switch>
                    </Router>
                </Provider>
            </React.Fragment>
        )
    }
}
