const BLUE = `#004c9d`
const DARK_BLUE = `#014c9c`
const LIGHT_GREY = `#ebebeb`

export const Colors = {
    navBar: BLUE,
    ctaButton: DARK_BLUE,
    containerBackground: LIGHT_GREY,
    footer: BLUE
}
