export const PAGE_PADDING = 36

// default <p> uses Barlow.regular
export const Barlow = {
    black: `barlowblack`,
    black_italic: `barlowblack_italic`,
    light: `barlowlight`,
    medium: `barlowmedium`,
    regular: `barlowregular`,
    semibold: `barlowsemibold`,
    extra_light: `barlowextralight`
}
