const heroImage = require('./heroImage.jpg')
const learnMoreButton = require('./learnMoreButton.png')
const mediaKitText = require('./mediaKitText.png')
const polarisLogo = require('./polarisLogoBlue.png')
const poster_Bennington = require('./poster_Bennington.png')
const poster_Godfrey = require('./poster_Godfrey.png')
const poster_Hurricane = require('./poster_Hurricane.png')
const poster_FourWheeler = require('./fourWheels.png')
const poster_IndianBobber = require('./poster_IndianBobber.png')
const poster_IndianFTR = require('./poster_IndianFTR.png')
const poster_Ranger = require('./poster_Ranger2020.png')
const poster_General = require('./poster_General.png')
const poster_RZR = require('./poster_RZR.png')
const poster_Slingshot = require('./slingshoter.png')
const poster_2020SlingshotR = require('./poster_2020SlingshotR.png')
const poster_Snowmobile = require('./snowmobike.png')
const rotationButton = require('./rotationButton.png')
const scrollButton = require('./scrollButtonNew.png')
const viewIn3DButton = require('./viewIn3DButton.png')
const thinkOutside = require('./thinkOutside.png')
const mobileHero = require('./mobileHero.jpg')
const water = require('./water.jpg')
const closeIcon = require('./Close_Icon.png')
const box3D = require('./box-3d.png')
const viewInAr = require('./ViewInAR.png')
const downArrow = require('./downArrow.png')
const indianLogo = require('./logo-indian.png')
const indianChooseWisely = require('./choose-wisely.png')
const indianWhoWeAre = require('./indian-who-we-are-hero-lg.jpg')
const indianWhoWeAreMobile = require('./indian-who-we-are-hero-xs.jpg')

export const images = {
    heroImage,
    learnMoreButton,
    mediaKitText,
    polarisLogo,
    poster_Bennington,
    poster_Godfrey,
    poster_Hurricane,
    poster_FourWheeler,
    poster_IndianBobber,
    poster_IndianFTR,
    poster_Ranger,
    poster_General,
    poster_RZR,
    poster_Slingshot,
    poster_2020SlingshotR,
    poster_Snowmobile,
    rotationButton,
    scrollButton,
    viewIn3DButton,
    thinkOutside,
    mobileHero,
    water,
    closeIcon,
    box3D,
    viewInAr,
    downArrow,
    indianLogo,
    indianChooseWisely,
    indianWhoWeAre,
    indianWhoWeAreMobile
}
